import React, { useRef } from 'react';
import Header from 'src/components/header';
import Footer from 'src/components/footer';
import HtmlHead from 'src/components/htmlHead';
import useIntersectionObserver from 'src/shared/hooks/useIntersectionObserver';
import useSaveToken from 'src/shared/hooks/useSaveToken.js';
import * as styles from './styles.module.scss';
import 'src/styles/index.scss';
import './blocks.scss';

export default function ArticleLayout({
  articleHeader,
  children,
  description,
  image,
  sideBar = [],
  slug,
  title,
}) {
  useSaveToken();
  const ref = useRef();
  const commentsVisible = useIntersectionObserver(ref);
  const Comments = React.lazy(() => import('src/components/comments'));

  return (
    <div className={styles.container}>
      <HtmlHead title={title} description={description} isArticle={true} image={image} />
      <Header />
      <div className={styles.wrapper}>
        { articleHeader }
        <div className={styles.cols}>
          <article>
            {children}
          </article>
          <aside>
            {
              sideBar.map(Widget => Widget)
            }
          </aside>
        </div>
        <div id="comments" />
        <div ref={ref} />
        {
          commentsVisible
            && <React.Suspense fallback="Cargando comentarios...">
            <Comments slug={slug} />
          </React.Suspense>
        }
      </div>
      <Footer />
    </div>
  );
}