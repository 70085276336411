import { useEffect } from 'react';

export default function useSaveToken() {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
    if (token) {
      localStorage.setItem('token', token)
      window.location.href = window.location.href.split('?')[0];
    }
  }, []);
}
