import { useState, useEffect } from 'react';

export default function useIntersectionObserver(ref) {
  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIntersecting(true);
          observer.unobserve(ref.current);
        }
      }
    )
    observer.observe(ref.current);
    return () => {
      observer.disconnect()
    }
  }, [])

  return isIntersecting
}